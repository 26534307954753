import { add, startOfMonth } from "date-fns";
import { cloneDeep } from "lodash";

export type TDebt = {
  id: string; //randomly generated value used for unique indexes for react mapping
  name: string;
  type: TDebtType;
  remainingBalance: number;
  minimumPayment: number;
  interestRate: number;
  //in certain cases, debt types that are normally not dischargeable we set to dischargeable
  overrideDischargeable: boolean;
};

export type TDebtAtTime = {
  x: Date;
  y: number;
};

//https://stackoverflow.com/questions/45251664/typescript-derive-union-type-from-tuple-array-values
export const DebtTypes = [
  "autoLoan",
  "homeLoan",
  "creditCard",
  "domesticSupport",
  "medical",
  "education",
  "government",
  "other",
] as const;

export type TDebtType = typeof DebtTypes[number];

//with a low enough payment and high enough interest rate, can just never pay off debt. At that point we abort
export const maxMonths = 1200;

export const getNonDischargeableDebts = (debts: TDebt[]) => {
  const nonDischargeableDebtTypes: TDebtType[] = ["autoLoan", "homeLoan", "domesticSupport", "education", "government"];
  return debts.filter(
    (debt) => debt.type && nonDischargeableDebtTypes.includes(debt.type) && !debt.overrideDischargeable
  );
};

//mutates the debt to increase its remaining balance by the interest value
const applyOneMonthOfInterest = (debt: TDebt) => {
  debt.remainingBalance = debt.remainingBalance * (1 + debt.interestRate / 100);
};

const getDebtIterator = (debts: TDebt[]) => {
  const newDebts = [...debts];
  return () => newDebts.shift();
};

export const computeDebtOverTime = (debts: TDebt[], monthlyPayment: number) => {
  const avalancheStrategyComparison = (debtA: TDebt, debtB: TDebt) => debtB.interestRate - debtA.interestRate;
  //do a deep clone because we're going to keep track of payments by mutating the debt objects themselves
  let debtsOrderedByStrategy = cloneDeep(debts).sort(avalancheStrategyComparison);

  let months = 0;
  let debtOverTime: TDebtAtTime[] = [];

  // const formatted = data.map((value, index) => ({
  //   x: format(startOfMonth(add(new Date(), { months: index + 1 })), "yyyy-MM-dd"),
  //   y: Math.round(value),
  // }));

  while (debtsOrderedByStrategy.length > 0 && months < maxMonths) {
    let currentMonthlyPayment = monthlyPayment;

    const getNextDebt = getDebtIterator(debtsOrderedByStrategy);
    let currentDebtToPayOff = getNextDebt();

    //attempt to pay the minimum payment of debts, in order.
    while (currentMonthlyPayment > 0 && currentDebtToPayOff) {
      //if we can make the min debt payment on a debt
      if (currentMonthlyPayment > currentDebtToPayOff.minimumPayment) {
        //make the payment, loop to the next value
        const subtractedValue = Math.min(currentDebtToPayOff.remainingBalance, currentDebtToPayOff.minimumPayment);
        currentDebtToPayOff.remainingBalance -= subtractedValue;
        currentMonthlyPayment -= subtractedValue;
        currentDebtToPayOff = getNextDebt();

        //if we can't make the min debt payment on a debt but can still pay it off
      } else if (currentMonthlyPayment > currentDebtToPayOff.remainingBalance) {
        currentMonthlyPayment -= currentDebtToPayOff.remainingBalance;
        currentDebtToPayOff.remainingBalance = 0;
        currentDebtToPayOff = getNextDebt();
        //if we can't make the min debt payment on a debt
      } else {
        //pay what we can
        currentDebtToPayOff.remainingBalance -= currentMonthlyPayment;
        currentMonthlyPayment = 0;
      }
    }

    const getNextDebt2 = getDebtIterator(debtsOrderedByStrategy);
    let currentDebtToPayOff2 = getNextDebt2();

    //If, after paying the minimum payments, there's remaining currentlyMonthlyPayment leftover, pay debts by order of priority again
    while (currentMonthlyPayment > 0 && currentDebtToPayOff2) {
      //if we can fully pay off a remaining balance with the current monthly payment
      if (currentMonthlyPayment >= currentDebtToPayOff2.remainingBalance) {
        //pay it off and move onto the next payment
        currentMonthlyPayment -= currentDebtToPayOff2.remainingBalance;
        currentDebtToPayOff2.remainingBalance = 0;
        currentDebtToPayOff2 = getNextDebt2();
      } else {
        //pay off as much as we can
        currentDebtToPayOff2.remainingBalance -= currentMonthlyPayment;
        currentMonthlyPayment = 0;
      }
    }

    //filter out 0s
    debtsOrderedByStrategy = debtsOrderedByStrategy.filter((debt) => debt.remainingBalance != 0);

    //increment counter, apply interest
    months += 1;
    const currentTotalDebt = debtsOrderedByStrategy.reduce((acc, debt) => acc + debt.remainingBalance, 0);
    const debtAtDate = {
      x: startOfMonth(add(new Date(), { months })),
      y: Math.round(currentTotalDebt),
    };
    debtOverTime.push(debtAtDate);
    debtsOrderedByStrategy.map(applyOneMonthOfInterest);
  }

  return debtOverTime;
};
