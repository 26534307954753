import { uniqueId } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { Button, H2, H3, H4, H5, H6, HR, Input, P, Panel, Select, Small, theme } from "@upsolve/ui";
import GlobalStyle from "../../components/GlobalStyle";

import { TDebt } from "../../components/DebtRepaymentCalculator/shared";
import { Intake } from "../../components/DebtRepaymentCalculator/Intake";
import { Results } from "../../components/DebtRepaymentCalculator/Results";

const trackingPageContext = {
  pageComponentName: "DebtRepaymentCalculator",
  pageComponentVersion: "0",
};

const DebtRepaymentCalculator = () => {
  const defaultDebts: TDebt[] = [
    {
      id: uniqueId(),
      name: "Credit Card Debt",
      type: "creditCard",
      remainingBalance: 12000,
      minimumPayment: 600,
      interestRate: 3,
      overrideDischargeable: false,
    },
    {
      id: uniqueId(),
      name: "Student Loan Debt",
      type: "education",
      remainingBalance: 6000,
      minimumPayment: 300,
      interestRate: 1,
      overrideDischargeable: false,
    },
  ];
  const [debts, setDebts] = useState<Array<TDebt>>(defaultDebts);

  const [isViewingResults, setIsViewingResults] = useState<boolean>(false);

  const setDebt = (index: number) => (debt: TDebt) => {
    const newDebts = [...debts];
    newDebts[index] = debt;
    setDebts(newDebts);
  };

  const onCompute = () => {
    window.scrollTo(0, 0);
    setIsViewingResults(true);
  };

  const getNewDebt: () => TDebt = () => ({
    id: uniqueId(),
    name: "",
    type: "creditCard",
    remainingBalance: 0,
    minimumPayment: 0,
    interestRate: 0,
    overrideDischargeable: false,
  });

  const addDebt = () => setDebts([...debts, getNewDebt()]);
  const removeDebt = (index: number) => () => setDebts(debts.filter((_, i) => index !== i));

  return (
    <Fragment>
      <Helmet>
        <title>Debt Repayment Calculator | Upsolve</title>
        <meta name="description" content="Determine how long it will take to pay off a debt at any interest rate." />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <StyledDebtRepaymentCalculator>
          {!isViewingResults ? (
            <Intake setDebt={setDebt} onCompute={onCompute} addDebt={addDebt} removeDebt={removeDebt} debts={debts} />
          ) : (
            <Results
              debts={debts}
              onBack={() => setIsViewingResults(false)}
              trackingPageContext={trackingPageContext}
            />
          )}
        </StyledDebtRepaymentCalculator>
        </ThemeProvider>
    </Fragment>
  );
};

const StyledDebtRepaymentCalculator = styled.main`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
`;

export default DebtRepaymentCalculator;
