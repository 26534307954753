import { A, Button, H2, H3, H4, H5, H6, HR, Input, P, Panel, Select, Small, theme } from "@upsolve/ui";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
  ChartOptions,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { add, format, startOfMonth } from "date-fns";

import { TDebt, TDebtType, TDebtAtTime, getNonDischargeableDebts, computeDebtOverTime, maxMonths } from "./shared";
import { MyUpsolveButtonLink } from "../Links/MyUpsolveLink";
import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";
import trackComponentAction from "../analytics/trackComponentAction";

ChartJS.register(...registerables);

const StyledResults = styled.div`
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    padding: 36px;
		@media (max-width: ${(props) => props.theme.breakpoints[500]}) {
			padding: 16px;
		}
  }
  .header {
    display: flex;
    align-items: center;
    .left, .right {
      flex: 1;
    }
  }
  .result-cards {
    display: flex;
    gap: 20px;
		@media (max-width: ${(props) => props.theme.breakpoints[500]}) {
			flex-direction: column;
		}
    .result-card {
      hr {
        margin: 1em 0;
      }
      width: 50%;
			@media (max-width: ${(props) => props.theme.breakpoints[500]}) {
				width: 100%;
			}
      flex-grow: 1;
      text-align: center;
      padding: 15px;
      border: 1px solid ${(props) => props.theme.colors.white["500"]};
      box-shadow: ${(props) => props.theme.effects.shadow["300"]};
      .field {
        padding-bottom: 15px;
      }
    }
    .filed-card {
      background-color: ${(props) => props.theme.colors.brand["500"]};
    }
  }
  .slider-section {
    text-align: center;
		width: 50%;
    margin 0 auto 16px;
  }
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
  }

  .labels {
    display: flex;
    flex-direction: horizontal;
  }

  .disclaimer {
    padding: 16px 16px 24px 16px;
  }

  h2 {
    text-align: center;
  }
  .instructions {
    padding-bottom: 8px;
    p {
      margin: 10px 8px;
    }
  }
  .cta {
    margin: 0 auto;
    text-align: center;
    width: 50%;
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      width: 90%;
		}
  }
`;

const trackingProps = {
  componentName: "Results",
  componentVersion: "0",
};

type TResultsProps = {
  debts: TDebt[];
  onBack: () => void;
  trackingPageContext: Object;
};

export const Results = (props: TResultsProps) => {
  const getTotalMinPayment = (debts: TDebt[]) =>
    debts.map((debt) => debt.minimumPayment).reduce((accumulator, newValue) => accumulator + newValue, 0);

  const nonDischargeableDebts = getNonDischargeableDebts(props.debts);

  //we use the min payment on non dischargeable debts and add 100 to make it very obvious how payment differs between the scenarios with lower monthly payments

  const defaultMonthlyPayment =
    nonDischargeableDebts.length === 0
      ? getTotalMinPayment(props.debts)
      : getTotalMinPayment(nonDischargeableDebts) + 100;
  const [monthlyPayment, setMonthlyPayment] = useState(defaultMonthlyPayment);

  const totalDebtOverTime = computeDebtOverTime(props.debts, monthlyPayment);
  const totalDebtOverTimeAfterFiling = computeDebtOverTime(nonDischargeableDebts, monthlyPayment);

  const getEstimatedFinalPaymentDate = (months: number) => {
    if (months === 0) {
      return "Debt-Free After Discharge";
    }
    if (months === maxMonths) {
      return "Cannot Pay Off";
    }

    const debtFreeDate = add(new Date(), { months });
    return format(debtFreeDate, "MMMMMM yyyy");
  };
  const debtFreeDate = getEstimatedFinalPaymentDate(totalDebtOverTime.length);
  const afterFilingDebtFreeDate = getEstimatedFinalPaymentDate(totalDebtOverTimeAfterFiling.length);

  const formatMonthlyPayment = (debts: TDebt[]) => {
    if (debts.length === 0) {
      return "$0 ($0)";
    }
    return `$${monthlyPayment} ($${getTotalMinPayment(debts)})`;
  };

  return (
    <StyledResults>
      <div className="content">
        <div className="header">
          <div className="left">
            <Button size="sm" inverted onClick={props.onBack}>
              {"← Back"}
            </Button>
          </div>
          <H2>Debt Repayment Timeline</H2>
          <div className="right"></div>
        </div>
        <div className="instructions">
          <Small>
            The results below are an estimate to help you understand and visualize what debt repayment looks like and
            are not intended as a payment plan. Calculations were done using the{" "}
            <A href="/learn/debt-avalanche-method/">Avalanche debt repayment method</A>.
          </Small>
          <Small>
            The debts that remain after filing for bankruptcy are debts that you cannot discharge (remove) with
            bankruptcy. Non-dischargeable debts include student loans, certain home loans and auto loans, government
            debts, and child support payments.
          </Small>
          <Small>
            <b>Instructions:</b> Drag the slider to adjust your monthly payment and see how different amounts affect
            your estimated final payment date and debt repayment timeline.
          </Small>
        </div>
        <div className="slider-section">

          <H6 color="grey">Given a Monthly Payment of...</H6>
          <H3>{`$${monthlyPayment}`}</H3>
          <input
            type="range"
            min="100"
            step="100"
            max={getTotalMinPayment(props.debts) * 2}
            value={monthlyPayment}
            onChange={(e) => setMonthlyPayment(Number(e.target.value))}
            className="slider"
            id="myRange"
          />
        </div>

        <Chart totalDebtOverTime={totalDebtOverTime} totalDebtOverTimeAfterFiling={totalDebtOverTimeAfterFiling} />

        <div className="result-cards">
          <div className="result-card">
            <H5>Without Filing Bankruptcy</H5>
            <HR />
            <div className="field">
              <H6 color="grey">Estimated Final Payment Date</H6>
              <H3>{debtFreeDate}*</H3>
            </div>
            <div className="field">
              <H6 color="grey">Payments Made Towards Debts</H6>
              <H3>{`$${props.debts.reduce((accumulator, debt) => accumulator + debt.remainingBalance, 0)}`}</H3>
            </div>
            <div className="field">
              <H6 color="grey">Monthly Payment (vs Minimum)</H6>
              <H3>{formatMonthlyPayment(props.debts)}</H3>
            </div>
          </div>
          <div className="result-card filed-card">
            <H5 color="white">If Filing Bankruptcy</H5>
            <HR />
            <div className="field">
              <H6 color="white" colorVariant={300}>
                Estimated Final Payment Date
              </H6>
              <H3 color="white">{afterFilingDebtFreeDate}*</H3>
            </div>
            <div className="field">
              <H6 color="white" colorVariant={300}>
                Payments Made Towards Debts
              </H6>
              <H3 color="white">{`$${nonDischargeableDebts.reduce(
                (accumulator, debt) => accumulator + debt.remainingBalance,
                0
              )}`}</H3>
            </div>
            <div className="field">
              <H6 color="white" colorVariant={300}>
                Monthly Payment (vs Minimum)
              </H6>
              <H3 color="white">{formatMonthlyPayment(nonDischargeableDebts)}</H3>
            </div>
          </div>
        </div>
        <Small className="disclaimer">
          *Final Payment Date is estimated with the monthly payment you selected at the top. If this value is lower than
          the minimum monthly payment, there is risk for defaulting on debts. This is not financial or legal advice.
        </Small>

        {/* <HR />
        <div className="cta">
          <P>
            Considering filing bankruptcy? Take our screener to see if you qualify to use our <b>free</b> tool.
          </P>
          <MyUpsolveButtonLink
            size="lg"
            href={getMyUpsolveEntryUrl({
              triggeredByActionId: "debtRepaymentCalculatorResults.myUpsolveNavigation",
            })}
            onClick={() => {
              trackComponentAction({
                ...trackingProps,
                actionId: "debtRepaymentCalculatorResults.myUpsolveNavigation",
                actionMethod: "click",
                pageContext: props.trackingPageContext,
              });
            }}
          >
            Get Started
          </MyUpsolveButtonLink>
        </div> */}
      </div>
    </StyledResults>
  );
};

//how does the chart look on mobile
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StyledChart = styled.div`
  margin: 0 auto 1em;
  padding-top: 15px;
  min-width: 96%;
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    width: 100%;
  }
`;

const Chart = ({
  totalDebtOverTime,
  totalDebtOverTimeAfterFiling,
}: {
  totalDebtOverTime: TDebtAtTime[];
  totalDebtOverTimeAfterFiling: TDebtAtTime[];
}) => {
  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      //cannot get this to work, handled decimation manually. Supposedly need parsing set to false
      // decimation: {
      //   enabled: true,
      //   algorithm: "lttb",
      //   samples: 50,
      // },
    },
    // parsing: false,
    scales: {
      x: {
        type: "time",
        ticks: {
          maxTicksLimit: 10,
          autoSkip: true,
        },
      },
      y: {
        title: {
          display: true,
          text: "Debt ($)",
        },
      },
    },
  };

  const maxMonths = Math.max(totalDebtOverTime.length, totalDebtOverTimeAfterFiling.length);

  const dataFormatter = (data: TDebtAtTime[]) => {
    const formatted = data
      .map(({ x, y }) => ({
        x: format(x, "yyyy-MM-dd"),
        y: y,
      }))
      .filter((date) => date.y < 999999); //set a clamp on exponentially growing values
    //handle decimation manually because I can't get it to work in chartjs

    if (formatted.length === 0) {
      return [];
    }
    if (maxMonths > 96) {
      const ret = formatted.filter((val, index) => index % 6 === 0);
      //ugly- if decimation removes the $0 data point, manually re-add
      if (ret[ret.length - 1].y !== 0) ret.push(formatted[formatted.length - 1]);
      return ret;
    }
    if (maxMonths > 48) {
      const ret = formatted.filter((val, index) => index % 2 === 0);
      //ugly- if decimation removes the $0 data point, manually re-add
      if (ret[ret.length - 1].y !== 0) ret.push(formatted[formatted.length - 1]);
      return ret;
    }

    return formatted;
  };

  const data = {
    datasets: [
      {
        label: "Continue To Pay Debts",
        data: dataFormatter(totalDebtOverTime),
        borderColor: theme.colors.gray[700],
        backgroundColor: theme.colors.gray[700],
      },
      {
        label: "After Filing For Bankruptcy",
        data: dataFormatter(totalDebtOverTimeAfterFiling),
        borderColor: theme.colors.brand[500],
        backgroundColor: theme.colors.brand[500],
      },
    ],
  };
  return (
    <StyledChart>
      <Line options={options} height={120} data={data} />
    </StyledChart>
  );
};
