import { Button, H2, H3, H4, H5, H6, HR, IconX, Input, P, Panel, Select, Small, theme } from "@upsolve/ui";
import styled from "styled-components";

import React, { useEffect, useState } from "react";
import { DebtTypes, TDebt, TDebtType } from "./shared";

const StyledDebt = styled.div`
  position: relative;
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.white["700"]};
  box-shadow: ${(props) => props.theme.effects.shadow["300"]};
  .primary-inputs {
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      flex-direction: column;
    }
    label + label {
      padding-left: 4px;
    }
    .debt-type {
      flex-basis: 280px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        flex-basis: unset;
      }
    }
    .number-field {
      flex-basis: 190px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        flex-basis: unset;
      }
    }
  }
  .secondary-inputs {
    padding-top: 10px;
    display: flex;
    align-items: center;
    input {
      margin-left: 6px;
    }
  }
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 14px;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(p) => p.theme.colors.gray[900]};
    }
  }
  align-self: start;
  margin-top: -8px;
  margin-right: -8px;
  &:hover {
    cursor: pointer;
  }
`;

const DebtTypeDisplayNames: Record<TDebtType, string> = {
  autoLoan: "Auto Loan",
  homeLoan: "Home Loan",
  creditCard: "Credit Card Loan",
  domesticSupport: "Domestic Support Debt",
  government: "Government or Tax Debt",
  medical: "Medical Debt",
  education: "Education Loan",
  other: "Other",
};

type TDebtProps = {
  debt: TDebt;
  setDebt: (debt: TDebt) => void;
  removeDebt: () => void;
};

const Debt = ({ debt, setDebt, removeDebt }: TDebtProps) => {
  const [type, setType] = useState<TDebtType>(debt.type);
  const [name, setName] = useState<string>(debt.name);
  const [remainingBalance, setRemainingBalance] = useState<any>(debt.remainingBalance);
  const [minimumPayment, setMinimumPayment] = useState<any>(debt.minimumPayment);
  const [interestRate, setInterestRate] = useState<any>(debt.interestRate);

  //TODO: default these to overrideDischargeable if it's set?
  const [stillOwnsHome, setStillOwnsHome] = useState<boolean>(true);
  const [willGiveUpCar, setWillGiveUpCar] = useState<boolean>(false);

  //this way we allow null inputs on the number fields, but the fields we submit aren't nullable
  useEffect(() => {
    const newDebt = {
      id: debt.id,
      type,
      name,
      remainingBalance: Number(remainingBalance),
      minimumPayment: Number(minimumPayment),
      interestRate: Number(interestRate),
      overrideDischargeable: (type === "homeLoan" && !stillOwnsHome) || (type === "autoLoan" && willGiveUpCar),
    };

    setDebt(newDebt);
  }, [type, name, remainingBalance, minimumPayment, interestRate, stillOwnsHome, willGiveUpCar]);

  return (
    <StyledDebt>
      <CloseButton onClick={removeDebt}>
        <IconX />
      </CloseButton>
      <div className="primary-inputs">
        <label className="debt-type">
          <Small>Debt Type</Small>
          <Select size="sm" defaultValue={type} onChange={(e) => setType(e.target.value as TDebtType)}>
            {DebtTypes.map((debtType) => (
              <option value={debtType}>{DebtTypeDisplayNames[debtType]}</option>
            ))}
          </Select>
        </label>
        <label>
          <Small>Lender Name</Small>
          <Input type="text" size="sm" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label className="number-field">
          <Small>Remaining Balance ($)</Small>
          <Input type="number" size="sm" value={remainingBalance} onChange={(e) => setRemainingBalance(e.target.value)} />
        </label>
        <label className="number-field">
          <Small>Minimum Payment ($)</Small>
          <Input type="number" size="sm" value={minimumPayment} onChange={(e) => setMinimumPayment(e.target.value)} />
        </label>
        <label className="number-field">
          <Small>Interest Rate (%)</Small>
          <Input type="number" size="sm" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
        </label>
      </div>
      {debt.type === "homeLoan" && (
        <div className="secondary-inputs">
          <span>Do you still own the home?</span>
          <Input type="checkbox" size="sm" checked={stillOwnsHome} onChange={() => setStillOwnsHome(!stillOwnsHome)} />
        </div>
      )}
      {debt.type === "autoLoan" && (
        <div className="secondary-inputs">
          <span>Would you be willing to give up your car automobile during bankruptcy?</span>
          <Input type="checkbox" size="sm" checked={willGiveUpCar} onChange={() => setWillGiveUpCar(!willGiveUpCar)} />
        </div>
      )}
    </StyledDebt>
  );
};

type TIntakeProps = {
  onCompute: () => void;
  setDebt: (index: number) => (debt: TDebt) => void;
  addDebt: () => void;
  removeDebt: (index: number) => () => void;
  debts: TDebt[];
};

export const Intake = (props: TIntakeProps) => {
  return (
    <StyledIntake>
      <div className="content">
        <H2>Debt Repayment Calculator</H2>
        <div className="instructions">
          <Small>
            This tool is intended to help you estimate, understand, and visualize the timeline for fully repaying your
            debt. It also presents an alternative timeline for debt repayment if you were to file for bankruptcy. This
            tool is not a payment plan.
          </Small>
          <Small>
            List all your major debts that you can think of. Documents that may be of use to you: credit card
            statements, bills, etc.
          </Small>
          <Small>
            <b>Note:</b> Some of your debts may be complicated, such as having variable interest or temporarily reduced
            payments. Put down your best estimates into the fields provided.
          </Small>
        </div>
        <div className="intake__debt-header">
          <H5>Debts</H5>
          <Button className="add-debt" size="sm" onClick={props.addDebt}>
            + Add Debt
          </Button>
        </div>
        {props.debts.map((debt, index) => (
          <Debt key={debt.id} debt={debt} setDebt={props.setDebt(index)} removeDebt={props.removeDebt(index)} />
        ))}
        <HR />
        <Button onClick={() => props.onCompute()}>Calculate Results</Button>
        <br />
      </div>
    </StyledIntake>
  );
};

const StyledIntake = styled.div`
  width: 100%;
  height: 100%;
  .content {
    padding: 36px;
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 16px;
    }
    display: flex;
    flex-direction: column;
    ${StyledDebt} + ${StyledDebt} {
      margin-top: 16px;
    }
  }
  .add-debt {
    align-self: end;
    width: 160px;
  }
  p {
    margin: 10px 0;
  }
  .instructions {
    padding: 8px 0;
  }
  h2 {
    text-align: center;
  }
  .intake__debt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }
`;
